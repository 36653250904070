<template>
  <b-card>
    <FormBuilder
      label="Service"
      update
      :data="$store.state.service.detailData"
      :fields="fields"
      :loading-detail="$store.state.service.loadingDetail"
      :loading-submit="$store.state.service.loadingSubmit"
      :diff-only="false"
      @load="loadData"
      @submit="submitData"
      @reset="resetData"
    />
  </b-card>
</template>

<script>
import FormBuilder from '@/layouts/components/FormBuilder.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormBuilder,
  },
  data() {
    const dummyCategorySelect = [
      {
        text: 'dummy category',
        value: '2212e332-2710-499c-a1b3-60e410d0d3ea',
      },
    ]

    const dummyGameelect = [
      {
        text: 'dummy game',
        value: '2212e332-2710-499c-a1b3-60e410d0d3ea"',
      },
    ]
    return {
      // Fields
      fields: [
        {
          key: 'name',
          label: 'Name',
          required: true,
        },
        {
          key: 'description',
          label: 'Description',
          type: 'textarea',
          minLength: 5,
        },
        {
          key: 'isActive',
          label: 'Status',
          type: 'switch',
        },
        {
          key: 'skillLevel',
          label: 'Skill Level',
          type: 'select',
          options: [
            { value: 'pro', text: 'PRO' },
            { value: 'intermediate', text: 'Intermediate' },
            { value: 'beginner', text: 'Beginner' },
          ],
        },
        {
          key: 'categoryID',
          label: 'Category',
          type: 'select',
          options: dummyCategorySelect,
        },
        {
          key: 'gameID',
          label: 'Game',
          type: 'select',
          options: dummyGameelect,
        },
        {
          key: 'languageCode',
          label: 'Language', // TODO: languange endpoint search
          type: 'select',
          options: [
            { text: 'Indonesia', value: 'id' },
          ],
        },
        {
          key: 'score',
          label: 'Rank Score',
          type: 'slider',
          min: -100,
          max: 100,
          marks: [-100, -50, 0, 50, 100],
        },
        {
          key: 'servicePrices[0].value',
          label: 'Service Prices',
          type: 'number',
        },
        {
          key: 'catalog',
          label: 'Service Type',
          type: 'select',
          options: [
            { value: 'service', text: 'Service' },
            { value: 'product', text: 'Product' },
            { value: 'physical', text: 'Physical' },
          ], // TODO: radio button
        },
        {
          key: 'product',
          label: 'Product',
          notes: "Only fill 'digital' if service is digital goods",
        },
        {
          key: 'media[0].type',
          label: 'Media Type',
          type: 'select',
          options: [
            { value: 'image', text: 'Image' },
            { value: 'video', text: 'Video' },
          ], // TODO: radio button
        },
        {
          key: 'media[0].url',
          label: 'Image Url',
        },
        {
          key: 'media[0].url',
          label: 'Upload Image',
          type: 'image',
        },
      ],
    }
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('service/getDetail', id)
    },
    submitData(id, data, callback) {
      this.$store.dispatch('service/update', { id, data })
        .then(callback)
    },
    resetData() {
      return this.$store.commit('service/SET_DETAIL_DATA', undefined)
    },
  },
}
</script>
